import {useState} from "react";

function tryParse(value: string) {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

export function useLocalStorage(key: string, initialValue?: any) {
  const [storedValue, setStoredValue] = useState(() => {
    const defaultValue = initialValue ?? null;
    try {
      const item = window.localStorage.getItem(key);
      if (item == null && defaultValue != null) {
        window.localStorage.setItem(key, defaultValue);
      }
      return item ? tryParse(item) : defaultValue;
    } catch (error) {
      console.error(error);
      return defaultValue;
    }
  });

  const setValue = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (value == null) {
        window.localStorage.removeItem(key);
        return;
      }
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
}