import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";
import { getAppConfig } from "../../appConfig";

export function Logout() {
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    if (inProgress !== 'none') return;
    const account = instance.getActiveAccount();
    if (!BrowserUtils.isInIframe() && account?.idTokenClaims?.isVerifiedAutofund) {
      window.location.assign(getAppConfig().SINGLE_LOG_OUT_URL);
    } else {
      instance.logoutRedirect({
        account,
        onRedirectNavigate: () => !BrowserUtils.isInIframe()
      })
    }
  }, [ instance, inProgress ]);

  return (
    <div>Logout</div>
  )
}