import { FunctionComponent, useEffect, useState } from "react";
import { useLocalStorage } from "../Hooks/useLocalStorage";

const ImageBasePath = "https://www.mysgi.ca/images";

export const Banner: FunctionComponent = () => {
  const GenericBannerPath = `${ImageBasePath}/banner.jpg`;

  const [issuerId, setIssuerId] = useLocalStorage("ISSUER_BANNER_ID", null);
  const [brokerId, setBrokerId] = useLocalStorage("BROKER_BANNER_ID", null);
  const [bannerImageSource, setBannerImageSource] = useState(GenericBannerPath);

  const isAtBaseUrl = window.location.pathname === "/";

  useEffect(() => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);

    let id = queryParams.get("issuer");
    if (id && id !== "") {
      setIssuerId(id);
      setBrokerId(null);
    } else {
      id = queryParams.get("broker");
      if (id && id !== "") {
        setBrokerId(id);
        setIssuerId(null);
      }
    }
    if (id) {
      setBannerImageSource(`${ImageBasePath}/banners/${id}.jpeg`);
    } else if (issuerId) {
      setBannerImageSource(`${ImageBasePath}/banners/${issuerId}.jpeg`);
    } else if (brokerId) {
      setBannerImageSource(`${ImageBasePath}/banners/${brokerId}.jpeg`);
    }

    if (!id && isAtBaseUrl) {
      setBannerImageSource(GenericBannerPath);
      setBrokerId(null);
      setIssuerId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="banner" data-testid="banner-test">
      <div id="banner">
        <img
          src={bannerImageSource}
          alt="banner"
          onError={() => setBannerImageSource(GenericBannerPath)}
          data-testid="issuer-or-broker-banner-test"
        />
      </div>
      <Vectors />
    </div>
  );
};

const Vectors: FunctionComponent = () => {
  return (
    <div className="vectors">
      <picture>
        <source
          media="(min-width: 1135px)"
          srcSet={`${ImageBasePath}/desktop_blue_vector.svg`}
        />
        <source
          media="(max-width: 1134px)"
          srcSet={`${ImageBasePath}/mobile_blue_vector.svg`}
        />
        <img src={`${ImageBasePath}/desktop_blue_vector.svg`} alt="" />
      </picture>
      <picture>
        <source
          media="(min-width: 1135px)"
          srcSet={`${ImageBasePath}/desktop_yellow_vector.svg`}
        />
        <source
          media="(max-width: 1134px)"
          srcSet={`${ImageBasePath}/mobile_yellow_vector.svg`}
        />
        <img src={`${ImageBasePath}/desktop_yellow_vector.svg`} alt="" />
      </picture>
    </div>
  );
};

export default Banner;
