import {useCallback} from "react";
import {getAppConfig} from "../../appConfig";
import {useLocalStorage} from "./useLocalStorage";

export function useCustomerDashboardRedirect() {
  const [issuerId] = useLocalStorage('ISSUER_BANNER_ID', null);
  const [brokerId] = useLocalStorage('BROKER_BANNER_ID', null);

  return useCallback(() => {
    let url = getAppConfig().REACT_APP_CUSTOMER_DASHBOARD_URL;
    if (issuerId) {
      url = `${url}/?issuer=${issuerId}`;
    } else if (brokerId) {
      url = `${url}/?broker=${brokerId}`;
    }
    window.location.assign(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
