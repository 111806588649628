import { Button } from "@sgi/gravity/Button";
import { Input } from "@sgi/gravity/Input";
import { Typography } from "@sgi/gravity/Typography";
import { DateInput } from "@sgi/gravity/DateInput";
import { ErrorAlert } from "@sgi/gravity/Alert";
import { MaskedValues } from "@sgi/gravity/MaskInput";
import { Box, VStack, Flex, Stack } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useCustomerDashboardRedirect } from "../Hooks/useCustomerDashboardRedirect";
import { defaultAppContext, useAppContext } from "../Common/Context/AppContext";
import { useMsal } from "@azure/msal-react";
import parse from "html-react-parser";
import { getAppConfig } from "../../appConfig";
import useFetchWithMsal from "../../Auth/useFetchWithMsal";
import { signUpRequest } from "../../Auth/authConfig";
import { ValidateCustomerResponse } from "../../Types/APIResponses/ValidateCustomerResponse";
import { useLocation } from "react-router-dom";
import { SgiCanadaLogo } from "@sgi/gravity/SgiCanadaLogo";

export default function EnterDetails() {
  const customerDashboardRedirect = useCustomerDashboardRedirect();
  const [errorMessage, setErrorMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [policyErrorMessage, setPolicyErrorMessage] = useState("");
  const [email, setEmail] = useState<string | undefined>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dob, setDob] = useState<MaskedValues>({
    value: "",
    floatValue: 0,
    formattedValue: "",
  });
  const [policyNumber, setPolicyNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const { setContext } = useAppContext();
  const { instance } = useMsal();
  const [lockedOutMessage, setLockedOutMessage] = useState("");
  const { isLoading, error, data, execute } = useFetchWithMsal(signUpRequest());
  const location = useLocation();
  const isRedirected = location.state;
  const [policyPattern, setPolicyPattern] = useState("");

  useEffect(() => {
    if (error) setErrorMessage(error.message);
    if (isLoading || !data) return;
    const response = data as ValidateCustomerResponse;
    if (response.isUserValid) {
      customerDashboardRedirect();
    } else {
      setErrorMessage(response.message);
      if (response.message.includes("locked out")) {
        setLockedOutMessage(response.message);
      }
    }
  }, [isLoading, error, data, customerDashboardRedirect]);

  async function handleSubmitClick() {
    if (lockedOutMessage) {
      setErrorMessage(lockedOutMessage);
      return;
    }
    const validateCustomerUri = `${
      getAppConfig().REACT_APP_BFF_API_BASEURL
    }/customer/validate`;
    await execute("POST", validateCustomerUri, {
      FirstName: firstName,
      LastName: lastName,
      DOB: dob.value,
      PolicyNumber: policyNumber,
      PostalCode: postalCode,
    });
  }

  useEffect(() => {
    setContext(defaultAppContext);
    const account = instance?.getActiveAccount()?.idTokenClaims;
    setEmail(String(account?.email));
    setPolicyPattern("^[AHTB]{1}[0-9]{11}");
  }, [setContext, instance]);

  function cancelBtnClick() {
    if (isRedirected) {
      customerDashboardRedirect();
      return;
    }
    instance.logout().catch((e) => console.error(e));
  }

  const onPolicyChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPolicyNumber(value);
    checkPolicy(e);
  };

  const checkPolicy = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      setIsButtonDisabled(false);
      setErrorMessage("");
      setPolicyErrorMessage("");
    } else {
      const isValid = e.target.checkValidity();
      if (isValid) {
        setPolicyErrorMessage("");
        setErrorMessage("");
        setIsButtonDisabled(false);
      } else {
        const indexOfDash = e.target.value.indexOf("-");
        if (indexOfDash === 9) {
          setErrorMessage(
            "The policy number you entered is not able to added to MySGI Beta at this time. For assistance with this policy, please speak with your broker."
          );
        }
        setPolicyErrorMessage("The number you entered is an invalid format.");
        setIsButtonDisabled(true);
      }
    }
  };

  return (
    <Flex
      direction="column"
      maxW={544}
      padding={38}
      mx="auto"
      alignContent={"center"}
      justifyContent={"center"}
      data-testid="enter-details-page"
    >
      <Box>
        <Typography variant="h2" data-testid="title-test" marginBottom={30}>
          Add your policy
        </Typography>
        <Stack direction={["row"]} align="stretch">
          <SgiCanadaLogo width="250px" />
          <Typography variant="body" mb={8}>
          You can only add policies with the 12-character format (one letter followed by 11 digits, no dashes) as shown on your policy invoice.
          </Typography>
        </Stack>
        <Box data-testid="error-container" mb={8} marginBottom={3}>
          {errorMessage && (
            <ErrorAlert onClose={() => setErrorMessage("")}>
              {parse(errorMessage)}
            </ErrorAlert>
          )}
        </Box>
        <Flex flexDirection="column" data-testid="details-test">
          <form onSubmit={(e) => e.preventDefault()}>
            <VStack align="stretch">
              <Input
                name="email"
                marginBottom="4"
                label="Email"
                type="text"
                data-testid="email-test"
                isRequired={true}
                value={email}
                isReadOnly
              />
              <Stack direction={["column", "row"]} align="stretch">
                <Input
                  name="given-name"
                  label="First Name"
                  type="text"
                  data-testid="given-name-test"
                  autoComplete="given-name"
                  isRequired={true}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <Input
                  name="family-name"
                  label="Last Name"
                  type="text"
                  data-testid="family-name-test"
                  autoComplete="family-name"
                  isRequired={true}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Stack>
              <Box
                className="chakra-form__helper-text css-eyet2y"
                style={{ marginTop: 0 }}
                marginBottom={4}
              >
                Must match the primary policy holder as it appears on your
                policy invoice.
              </Box>
            </VStack>
            <Stack direction={["row"]} align="stretch">
              <DateInput
                name="birth-date"
                marginBottom="4"
                label="Date of Birth"
                data-testid="birth-date-test"
                autoComplete="bday"
                isRequired={true}
                value={dob?.formattedValue}
                onChange={(values) => {
                  setDob(values);
                }}
              />
              <Input
                name="postal-code"
                marginBottom="4"
                label="Postal Code"
                type="text"
                data-testid="postal-code-test"
                autoComplete="postal-code"
                isRequired={true}
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </Stack>
            <VStack align="stretch" marginBottom={4}>
              <Input
                name="policy-number"
                label="Policy Number"
                type="text"
                data-testid="policy-number-test"
                isRequired={true}
                formHelperText={
                  !policyErrorMessage &&
                  "You can find your policy number in the policy invoice"
                }
                value={policyNumber}
                onChange={onPolicyChange}
                pattern={policyPattern}
                error={policyErrorMessage}
              />
            </VStack>
            <Stack direction={["row"]} align="stretch">
              <Button
                isFullWidth
                variant="ghost"
                marginTop="20px"
                marginBottom="80px"
                data-testid="password-cancel-button"
                onClick={() => cancelBtnClick()}
              >
                Cancel
              </Button>
              <Button
                isFullWidth
                isDisabled={isButtonDisabled}
                isLoading={isLoading}
                type="submit"
                onClick={handleSubmitClick}
                data-testid="create-account-button"
                marginTop="20px"
                marginBottom="80px"
              >
                Connect policies
              </Button>
            </Stack>
          </form>
        </Flex>
      </Box>
    </Flex>
  );
}
